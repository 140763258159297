<template>
  <vx-card no-shadow>
    <div class="vx-row">
      <div class="vx-col w-full ml-4"></div>
      <div v-if="this.$acl.check('admin')" class="vx-row w-full ml-4 mr-4">
        <div class="vx-col md:w-1/2 w-full">
          <label>Redirect to receipt after sale</label>
          <vs-switch v-model="settings.sale_redirect_to_receipt" />
        </div>
      </div>

      <div
        v-if="dataValid && this.$acl.check('admin')"
        @click="save"
        class="w-full ml-6 mr-6 mt-6 btn-add-new p-3 mb-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-white bg-primary"
      >
        <feather-icon icon="CheckIcon" svgClasses="h-4 w-4" />
        <span class="ml-2 text-base text-white">Save</span>
      </div>
    </div>
  </vx-card>
</template>

<script>
export default {
  name: 'setting',
  data() {
    return {
      settings: {
        sale_redirect_to_receipt: false,
      },
    };
  },
  computed: {
    logoUrl() {
      return this.$logo;
    },
    dataValid() {
      return !this.errors.any();
    },
  },
  methods: {
    print() {
      if (window.ipcRenderer) {
        window.ipcRenderer.send(
          'welcome',
          `Thank you for choosing Express POS. Printer is working as expected.`,
        );
      }
    },
    async save() {
      const settings = [];

      for (const setting in this.settings) {
        if (Object.hasOwnProperty.call(this.settings, setting)) {
          const settingValue = this.settings[setting];
          settings.push({
            key: setting,
            value: settingValue,
          });
        }
      }

      await this.$store.dispatch('updateSettingBulk', settings);

      this.$vs.notify({
        color: 'success',
        title: 'Settings Updated',
        text: 'Settings have been successfully updated',
      });
    },
    async getDefaultData() {
      const settings = this.$root.settingsData?.data;
      for (const setting in settings) {
        if (Object.hasOwnProperty.call(settings, setting)) {
          const settingValue = settings[setting];
          if (setting in this.settings) {
            this.settings[setting] = settingValue;
          }
        }
      }
    },
  },
  async created() {
    this.getDefaultData();
  },
};
</script>
