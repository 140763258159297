<template>
  <vx-card no-shadow>
    <div class="vx-row">
      <div class="vx-col w-full ml-4"></div>
      <div v-if="this.$acl.check('admin')" class="vx-row w-full ml-4 mr-4">
        <div class="vx-col w-full mb-8">
          <vs-input
            v-model="this.$root.businessData.data.currency"
            label="Default Currency"
            disabled="true"
            class="w-full"
            name="Default Currency"
          />
          <small
            >You can change the default currency in the General section</small
          >
        </div>
        <div class="vx-col w-full">
          <vs-table
            ref="table"
            pagination
            search
            :data="settings.alternativeCurrency"
          >
            <div
              slot="header"
              class="flex flex-wrap-reverse items-center flex-grow justify-between"
            >
              <div
                class="flex flex-wrap-reverse items-center data-list-btn-container"
              >
                <!-- ADD NEW -->
                <div
                  v-if="$acl.check('admin')"
                  class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-primary border border-solid border-primary"
                  @click="addCurrency"
                >
                  <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                  <span class="ml-2 text-base text-primary">Add New</span>
                </div>
              </div>
            </div>

            <template slot="thead">
              <vs-th sort-key="currency_code">Currency</vs-th>
              <vs-th sort-key="conversion_rate">Conversion Rate</vs-th>
              <vs-th sort-key="">Actions</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <p class="font-medium truncate">
                      {{ tr.currencyCode || '' }}
                    </p>
                  </vs-td>
                  <vs-td>
                    <p class="font-medium truncate">
                      {{ tr.conversionRate }}
                    </p>
                  </vs-td>
                  <vs-td class="whitespace-no-wrap" v-if="$acl.check('admin')">
                    <feather-icon
                      icon="EditIcon"
                      svgClasses="w-8 h-5 hover:text-primary stroke-current"
                      @click.stop="editData(tr, indextr)"
                    />
                    <feather-icon
                      icon="TrashIcon"
                      svgClasses="w-8 h-5 hover:text-danger stroke-current"
                      class="ml-4"
                      @click.stop="deleteData(tr, indextr)"
                    />
                  </vs-td>
                </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </div>
      </div>

      <vs-popup
        :active.sync="popupActive"
        class=""
        :title="`${isUpdate ? 'Update currency' : 'Add new currency'}`"
      >
        <p></p>
        <div class="vx-row">
          <div class="vx-col w-full mt-5">
            <vs-select
              v-validate="'required'"
              v-model="popupCurrency.countryCode"
              class="w-full select-large"
              label="Country"
              name="country"
              @input="updateCurrency"
            >
              <vs-select-item
                v-for="item in countryOptions"
                :key="item.countryCode"
                :value="item.countryCode"
                :text="item.countryName"
                class="w-full"
              />
            </vs-select>
            <span class="text-danger">{{ errors.first('country') }}</span>
          </div>
          <div class="vx-col w-full mt-5">
            <vs-input
              v-validate="'required'"
              v-model="popupCurrency.currency"
              label="Currency"
              disabled="true"
              class="w-full"
              name="currency"
            />
            <span class="text-danger">{{ errors.first('currency') }}</span>
          </div>

          <div v-if="popupCurrency.currency" class="vx-col w-1/2 mt-5">
            <vx-input-group class="mb-base">
              <template slot="prepend">
                <div class="prepend-text bg-primary">
                  <span>{{ popupCurrency.currency }}</span>
                </div>
              </template>

              <vs-input :disabled="true" :value="1" />

              <template slot="append">
                <div class="append-text bg-primary">
                  <span>.00</span>
                </div>
              </template>
            </vx-input-group>
            <span class="text-danger">{{ errors.first('currency') }}</span>
          </div>
          <div v-if="popupCurrency.currency" class="vx-col w-1/2 mt-5">
            <vx-input-group class="mb-base">
              <template slot="prepend">
                <div class="prepend-text bg-primary">
                  <span>{{ this.$root.businessData.data.currency }}</span>
                </div>
              </template>

              <vs-input v-model="popupCurrency.conversionRate" />

              <template slot="append">
                <div class="append-text bg-primary">
                  <span>.00</span>
                </div>
              </template>
            </vx-input-group>
            <span class="text-danger">{{ errors.first('currency') }}</span>
          </div>
        </div>
        <div
          v-if="popupDataValid && this.$acl.check('admin')"
          @click="insertCurrency"
          class="w-full mt-6 btn-add-new p-3 mb-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-white bg-primary"
        >
          <feather-icon icon="CheckIcon" svgClasses="h-4 w-4" />
          <span class="ml-2 text-base text-white">Save</span>
        </div>
      </vs-popup>

      <!-- <div
        v-if="dataValid && this.$acl.check('admin')"
        @click="save"
        class="w-full ml-6 mr-6 mt-6 btn-add-new p-3 mb-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-white bg-primary"
      >
        <feather-icon icon="CheckIcon" svgClasses="h-4 w-4" />
        <span class="ml-2 text-base text-white">Save</span>
      </div> -->
    </div>
  </vx-card>
</template>

<script>
import countries from '@/views/pages/system/installation/countries.json';

export default {
  name: 'setting',
  data() {
    return {
      countryOptions: countries.filter(
        (country) =>
          country.currencyCode != this.$root.businessData.data.currency,
      ),

      itemsPerPage: 10,
      popupActive: false,
      popupCurrency: {
        index: null,
        country: {},
        countryCode: null,
        currency: null,
        conversionRate: 1,
      },
      settings: {
        alternativeCurrency: [],
      },
    };
  },
  computed: {
    isUpdate() {
      return this.popupCurrency.index !== null;
    },
    logoUrl() {
      return this.$logo;
    },
    dataValid() {
      return !this.errors.any();
    },
    popupDataValid() {
      return (
        !this.errors.any() &&
        this.popupCurrency.currency &&
        this.popupCurrency.conversionRate > 0
      );
    },
  },
  watch: {
    '$root.settingsData.data.alternativeCurrency': function (val) {
      this.settings.alternativeCurrency = val;
    },
    popupActive: function (val) {
      if (!val) {
        this.resetPopup();
      }
    },
  },
  methods: {
    resetPopup() {
      this.popupCurrency = {
        index: null,
        countryCode: null,
        currency: null,
        conversionRate: 1,
      };
      this.popupActive = false;
    },
    deleteData(row, index) {
      this.settings.alternativeCurrency.splice(index, 1);

      this.resetPopup();

      this.$vs.notify({
        color: 'success',
        title: 'Currency Deleted',
        text: `Currency has been deleted successfully`,
      });

      this.save();
    },
    editData(row, index) {
      this.popupCurrency = {
        index: index,
        countryCode: row.countryCode,
        conversionRate: row.conversionRate,
      };
      this.popupCurrency.country = this.countryOptions.find(
        (co) => co.countryCode === this.popupCurrency.countryCode,
      );
      this.popupCurrency.currency = this.popupCurrency?.country?.currencyCode;
      this.popupActive = true;
    },
    addCurrency() {
      // Show popup to select currency
      this.popupActive = true;
    },
    updateCurrency() {
      this.popupCurrency.countryCode;
      this.popupCurrency.country = this.countryOptions.find(
        (co) => co.countryCode === this.popupCurrency.countryCode,
      );
      this.popupCurrency.currency = this.popupCurrency.country.currencyCode;
    },
    insertCurrency() {
      if (this.isUpdate) {
        this.settings.alternativeCurrency[this.popupCurrency.index] = {
          countryCode: this.popupCurrency.countryCode,
          currencyCode: this.countryOptions.find(
            (co) => co.countryCode === this.popupCurrency.countryCode,
          )?.currencyCode,
          conversionRate: parseFloat(this.popupCurrency.conversionRate),
        };
      } else {
        this.settings.alternativeCurrency.push({
          countryCode: this.popupCurrency.countryCode,
          currencyCode: this.countryOptions.find(
            (co) => co.countryCode === this.popupCurrency.countryCode,
          )?.currencyCode,
          conversionRate: parseFloat(this.popupCurrency.conversionRate),
        });
      }

      this.resetPopup();

      this.$vs.notify({
        color: 'success',
        title: 'Currency Added',
        text: `Currency has been added successfully`,
      });

      this.save();
    },
    async save() {
      const settings = [];

      for (const setting in this.settings) {
        if (Object.hasOwnProperty.call(this.settings, setting)) {
          const settingValue = this.settings[setting];
          settings.push({
            key: setting,
            value: settingValue,
          });
        }
      }

      await this.$store.dispatch('updateSettingBulk', settings);

      this.$vs.notify({
        color: 'success',
        title: 'Settings Updated',
        text: 'Settings have been successfully updated',
      });
    },
    async getDefaultData() {
      const settings = this.$root.settingsData?.data;
      console.log('row', settings);
      for (const setting in settings) {
        if (Object.hasOwnProperty.call(settings, setting)) {
          const settingValue = settings[setting];
          if (setting in this.settings) {
            this.settings[setting] = settingValue;
          }
        }
      }
    },
  },
  async created() {
    this.getDefaultData();
  },
};
</script>
