<template>
  <vs-tabs
    id="profile-tabs"
    :position="isSmallerScreen ? 'top' : 'left'"
    :key="isSmallerScreen"
    class="tabs-shadow-none"
  >
    <!-- GENERAL -->
    <vs-tab
      :label="!isSmallerScreen ? 'General' : ''"
      icon-pack="feather"
      icon="icon-user"
    >
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <settings-general />
      </div>
    </vs-tab>
    <vs-tab
      :label="!isSmallerScreen ? 'Print' : ''"
      icon-pack="feather"
      icon="icon-printer"
    >
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <settings-print />
      </div>
    </vs-tab>

    <vs-tab
      :label="!isSmallerScreen ? 'Sale' : ''"
      icon-pack="feather"
      icon="icon-shopping-cart"
    >
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <settings-sale />
      </div>
    </vs-tab>
    <vs-tab
      :label="!isSmallerScreen ? 'Currency & Conversion' : ''"
      icon-pack="feather"
      icon="icon-dollar-sign"
    >
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <settings-currency />
      </div>
    </vs-tab>
  </vs-tabs>
</template>

<script>
import SettingsCurrency from './SettingsCurrency.vue';
import SettingsGeneral from './SettingsGeneral.vue';
import SettingsPrint from './SettingsPrint.vue';
import SettingsSale from './SettingsSale.vue';

export default {
  components: {
    SettingsGeneral,
    SettingsPrint,
    SettingsSale,
    SettingsCurrency,
  },
  data() {
    return {};
  },
  computed: {
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768;
    },
  },
};
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
