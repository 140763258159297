<template>
  <vx-card no-shadow>
    <div class="vx-row">
      <div class="vx-col w-full ml-4"></div>
      <div v-if="this.$acl.check('admin')" class="vx-row w-full ml-4 mr-4">
        <div class="vx-col md:w-1/2 w-full">
          <vs-input
            v-validate="'required'"
            v-model="businessSettings.business_name"
            label="Business Name"
            class="w-full"
            name="business_name"
          />
          <span class="text-danger">{{ errors.first('business_name') }}</span>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <vs-input
            v-validate="'required'"
            v-model="businessSettings.business_subtitle"
            label="Business Subtitle"
            class="w-full"
            name="business_subtitle"
          />
          <span class="text-danger">{{
            errors.first('business_subtitle')
          }}</span>
        </div>
        <div class="vx-col md:w-1/2 w-full mt-5">
          <vs-input
            v-validate="'required|numeric|max:10|min:10'"
            v-model="businessSettings.main_phone"
            label="Main Phone"
            class="w-full"
            name="main_phone"
          />
          <span class="text-danger">{{ errors.first('main_phone') }}</span>
        </div>
        <div class="vx-col md:w-1/2 w-full mt-5">
          <vs-input
            v-validate="'required'"
            v-model="businessSettings.postal_address"
            label="Postal Address"
            class="w-full"
            name="address"
          />
          <span class="text-danger">{{ errors.first('address') }}</span>
        </div>
        <div class="vx-col md:w-1/2 w-full mt-5">
          <vs-input
            v-validate="'required'"
            v-model="businessSettings.postal_city"
            label="Postal City"
            class="w-full"
            name="city"
          />
          <span class="text-danger">{{ errors.first('city') }}</span>
        </div>
        <div class="vx-col md:w-1/2 w-full mt-5">
          <vs-select
            v-validate="'required'"
            v-model="businessSettings.country"
            class="w-full select-large"
            label="Country"
            name="country"
            @input="updateCurrency"
          >
            <vs-select-item
              v-for="(item, index) in countryOptions"
              :key="index"
              :value="item"
              :text="item.countryName"
              class="w-full"
            />
          </vs-select>
          <span class="text-danger">{{ errors.first('country') }}</span>
        </div>
        <div class="vx-col md:w-1/2 w-full mt-5">
          <vs-input
            v-validate="'required'"
            v-model="businessSettings.currency"
            label="Currency"
            disabled="true"
            class="w-full"
            name="currency"
          />
          <span class="text-danger">{{ errors.first('currency') }}</span>
        </div>
        <vs-divider>Tax</vs-divider>
        <div class="vx-col md:w-1/2 w-full">
          <vs-input
            v-model="businessSettings.tax_pin"
            label="Tax PIN"
            class="w-full"
            name="pin"
          />
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <vs-input
            v-model="businessSettings.vat_reg"
            label="VAT Reg"
            class="w-full"
            name="vat_reg"
          />
        </div>
      </div>

      <div v-if="this.$acl.check('admin')" class="ml-4 mr-4 w-full">
        <div class="ml-2 mb-2 mt-6"><label>Theme Color</label></div>
        <ul class="clearfix">
          <!-- Color Square -->
          <li
            v-for="color in themeColors"
            :key="color"
            :style="{ backgroundColor: color }"
            :class="{ 'shadow-outline': color == primaryColor }"
            class="w-10 cursor-pointer h-10 rounded-lg m-2 float-left"
            @click="updatePrimaryColor(color)"
          />

          <!-- Custom Color Square -->
          <li
            :style="{ backgroundColor: customPrimaryColor }"
            :class="{
              'shadow-outline': customPrimaryColor == primaryColor,
            }"
            class="w-10 cursor-pointer h-10 rounded-lg m-2 float-left"
            @click="updatePrimaryColor(customPrimaryColor)"
          />
          <li class="float-left">
            <input
              v-model="customPrimaryColor"
              class="w-10 cursor-pointer h-10 rounded-lg m-2"
              type="color"
            />
          </li>
        </ul>
      </div>

      <div
        v-if="dataValid && this.$acl.check('admin')"
        @click="save"
        class="w-full ml-6 mr-6 mt-6 btn-add-new p-3 mb-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-white bg-primary"
      >
        <feather-icon icon="CheckIcon" svgClasses="h-4 w-4" />
        <span class="ml-2 text-base text-white">Update</span>
      </div>

      <vs-divider v-if="this.$acl.check('admin')"></vs-divider>

      <div
        v-if="this.$acl.check('admin')"
        class="vx-col w-full md:w-1/2 mt-base mb-4"
      >
        <input
          ref="logoInput"
          type="file"
          class="hidden"
          accept=".jpg, .jpeg, .png"
          @change="handleClick"
        />
        <div
          class="px-8 py-16 cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl"
          @click="$refs.logoInput.click()"
          @drop="handleDrop"
          @dragover="handleDragover"
          @dragenter="handleDragover"
        >
          <feather-icon
            icon="UploadCloudIcon"
            svg-classes="h-16 w-16 stroke-current text-grey"
            class="block"
          />
          <span>Drop Logo File or </span>
          <span
            class="font-medium text-primary"
            @click.stop="$refs.logoInput.click()"
            >Browse</span
          >
          <!-- <vs-button type="border" @click.stop="$refs.logoInput.click()">Browse</vs-button> -->
        </div>
      </div>

      <div v-if="$root.logo" class="vx-col w-full md:w-1/2 mt-base">
        <h5>Current Logo</h5>
        <img
          :src="$root.logo"
          height="180px"
          class="mx-auto mt-base"
          alt="logo"
        />
      </div>
    </div>
  </vx-card>
</template>

<script>
import idb from '@/db/idb';
import helperMixin from '@/helperPlugin';
import countries from '@/views/pages/system/installation/countries.json';

export default {
  name: 'setting',
  data() {
    return {
      showPopup: false,
      countryOptions: countries,

      customPrimaryColor: '#3DC9B3',
      themeColors: ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E'],

      businessSettings: {
        business_name: '',
        business_subtitle: '',
        // logo: '',
        main_phone: '',
        postal_address: '',
        postal_city: '',
        tax_pin: '',
        vat_reg: '',
        country: {},
        currency: '',
        theme: {
          primaryColor: this.primaryColor,
        },
      },
    };
  },
  mixins: [helperMixin],
  watch: {
    customPrimaryColor(val) {
      this.updatePrimaryColor(val);
    },
    // businessData: async (val) => {
    //   if ('_attachments' in val) {
    //     if (val._attachments.logo) {
    //       this.logo = await this.getLogo(val._id).then((blob) =>
    //         URL.createObjectURL(blob),
    //       );
    //       return;
    //     }
    //   }
    //   this.logo = '';
    // },
  },
  computed: {
    logoUrl() {
      return this.$logo;
    },
    dataValid() {
      return (
        !this.errors.any() &&
        this.businessSettings.business_name &&
        this.businessSettings.main_phone &&
        this.businessSettings.postal_address &&
        this.businessSettings.postal_city &&
        this.businessSettings.country &&
        this.businessSettings.currency
      );
    },
    primaryColor: {
      get() {
        return this.$store.state.themePrimaryColor;
      },
      set(val) {
        this.$store.commit('UPDATE_PRIMARY_COLOR', val);
      },
    },
  },
  methods: {
    print() {
      if (window.ipcRenderer) {
        window.ipcRenderer.send(
          'welcome',
          `Thank you for choosing Express POS. Printer is working as expected.`,
        );
      }
    },
    updatePrimaryColor(color) {
      this.primaryColor = color;
    },
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    },
    handleDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      const files = e.dataTransfer.files;
      if (files.length !== 1) {
        this.$vs.notify({
          title: 'Upload Failed',
          text: 'Only support uploading one file!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
        });
        return;
      }
      const rawFile = files[0]; // only use files[0]
      if (!this.isLogo(rawFile)) {
        this.$vs.notify({
          title: 'Upload Failed',
          text: 'Only supports upload .jpg, .jpeg, .png suffix files',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
        });
        return false;
      }
      this.uploadFile(rawFile);
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0];
      if (!rawFile) return;
      this.uploadFile(rawFile);
    },
    isLogo(file) {
      return /\.(jpg|jpeg|png)$/.test(file.name);
    },
    async uploadFile(file) {
      this.$refs['logoInput'].value = null; // fix can't select the same file

      // const base64File = await new Promise((resolve, reject) => {
      //   const reader = new FileReader();
      //   reader.readAsDataURL(file);
      //   reader.onload = () => resolve(reader.result);
      //   reader.onerror = reject;
      // });

      const attachment = new Blob([file], {
        type: file.type,
      });

      URL.revokeObjectURL(this.logo);

      this.$store
        .dispatch('updateLogo', {
          data: attachment,
          properties: file,
        })
        .then((res) => {
          console.log('logoUpdate', res);
          this.$vs.notify({
            color: 'success',
            title: 'Logo Updated',
            text: 'Logo has been successfully updated',
          });
        })
        .catch((err) => {
          this.$vs.notify({
            color: 'warning',
            title: 'Failed to update logo',
            text: `Unable to update logo. Error: ${err}`,
          });
        });
    },
    async save() {
      const settings = {};

      for (const setting in this.businessSettings) {
        if (Object.hasOwnProperty.call(this.businessSettings, setting)) {
          const settingValue = this.businessSettings[setting];
          settings[setting] = settingValue;
        }
      }

      settings.theme.primaryColor = this.primaryColor;

      await this.$store.dispatch('updateBusinessSettings', settings);

      this.$vs.notify({
        color: 'success',
        title: 'Settings Updated',
        text: 'Settings have been successfully updated',
      });
    },
    async getDefaultData() {
      const businessSettings = this.$root.businessData.data;
      for (const setting in businessSettings) {
        if (Object.hasOwnProperty.call(businessSettings, setting)) {
          const settingValue = businessSettings[setting];
          if (setting in this.businessSettings) {
            this.businessSettings[setting] = settingValue;
          }
        }
      }
    },
    updateCurrency() {
      this.businessSettings.currency =
        this.businessSettings.country.currencyCode;
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.timeout--;
      }, 1000);
    },
    async getRecord(type) {
      return await idb.getRecord(type);
    },
    async fill(a, t) {
      let d = (await this.getRecord(t)) || null;
      if (d) {
        for (let i = 0; i < a.length; i++) {
          const e = a[i];
          if (this.hasOwnProperty(e) && d.hasOwnProperty(e)) {
            this[`${e}`] = d[e];
          }
        }
        return d;
      }
      return false;
    },
  },
  async created() {
    this.getDefaultData();
  },
};
</script>
